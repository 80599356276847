<template>
  <div v-loading="loading">
    <el-form label-position="right">
     
      <title-and-description :field="field" />
      <!-- <el-input type="hidden" placeholder="Video Link" v-model="field.video_data_type" value="URL" /> -->
      <el-row type="flex" :gutter="30">
        <el-col :span="12" v-if="field.filled_by!='RECEIVER'">
          <el-form-item label="Video Link">
            <el-input
              placeholder="Video Link"
              v-model="field.video_source_url"
            />
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mt-2">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>

      <el-row type="flex" :gutter="30" v-if="field.filled_by!='RECEIVER'">
        <el-col :span="24">
          <span class="label">Thumbline Image</span>
          <el-upload
            drag
            name="upload"
            :accept="allowed_file_types"
            :action="UploadUrl"
            :on-change="uploadFile"
            :headers="uploadHeaders"
            :on-success="handleLogoSuccess"
            :on-error="handleLogoError"
            :before-upload="beforeLogoUpload"
            :thumbnail-mode="true"
            :show-file-list="false"
          >
            <div v-if="!fileUrl" style="padding: 1em">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
              </div>
            </div>
            <div v-if="fileUrl">
              <img
                v-loading="loading"
                style="width: 100%; height: 100%"
                class="avatar"
                :src="logoUploadUrl + fileUrl"
              />
            </div>
          </el-upload>
        </el-col>

        <el-col :span="12">
          <p :style="getStyle">
            <template v-if="this.field.video_thumbnail_url">
              <img
                :style="getUploadPreviewStyle"
                :src="logoUploadUrl + this.field.video_thumbnail_url"
              />
            </template>

            <template v-else>-</template>
          </p>
          <el-row type="flex" :gutter="30">
        <el-col :span="24">
        <is-field-required :field="field" class="field-required"/>
        </el-col>
      </el-row>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
// import TitleAndDescription from "./TitleAndDescription";
import { mapGetters } from "vuex";
import appConfig from "@/config/app";
import axios from "@/config/axios";
export default {
  name: "templates-formComponents-Video",
  components: {
    FieldFilledBy: () => import("./FieldFilledBy"),
    TitleAndDescription: () => import("./TitleAndDescription.vue"),
    "IsFieldRequired":()=>import("./IsFieldRequired"),
  },
  props: ["field"],

  data() {
    return {
      validations: [],
      file: "",
      video_thumbnail_url: "",
      video_source_url: "",
      loading: false,
      fileUploading: false,
      options: ["UPLOAD"],
      // options: ["UPLOAD", "LINK"],
      fileUrl: "",
      fileUploadError: "",
      imageWidth: 100,
      fileTypes: "image/jpeg,image/png",

      allowed_file_types: this.fileTypes,
    };
  },
  computed: {
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    UploadUrl() {
      return appConfig.API_URL + "/uploadToS3";
    },
    uploadHeaders() {
      return {
        Authorization: this.getAuthenticationDetails.access_token,
      };
    },
    getStyle() {
      return this.hasLabel ? `height:${this.field.height - 30}px` : "";
    },
    getUploadPreviewStyle() {
      return this.fileUrl ? `width:${100 / this.fileUrl}%` : "width:100%";
    },
    ...mapGetters("companyTemplates", ["getImageUploadData"]),
    ...mapGetters("templatesData", ["getDownloadUrl"]),
    ...mapGetters("auth", ["getAuthenticationDetails"]),

    getFileName() {
      let fileNames = this.field["upload_file"].name.split(".");

      if (fileNames.length > 1) {
        let name = fileNames[0];
        let ext = fileNames.pop();
        if (name.length > 20) {
          return name.slice(0, 20) + "." + ext;
        } else {
          return name + "." + ext;
        }
      } else {
        return name.slice(0, 20);
      }
    },
  },
  async mounted() {
    this.field.video_data_type = "URL";
    this.video_thumbnail_url =
      this.field && this.field.video_thumbnail_url
        ? this.field.video_thumbnail_url
        : "";
  },
  methods: {
    async uploadFile() {
      this.fileUploadError = "";
      this.loading = false;
    },
    handleLogoSuccess(res, file) {
      console.log(file);

      this.fileUrl = res.Location;
      this.imageWidth = 100 / this.fileUrl;
      (this.field.video_thumbnail_url = this.fileUrl),
      this.loading = false;
      this.$notify.success({
        title: "success",
        message: "Updated Successfully",
      });
    },
    async beforeLogoUpload(file) {
      this.loading = true;
      let isValidMime = true;
      isValidMime = file.size / 1024 / 1024 < 2;
      if (!isValidMime) {
        this.$message.error("picture size can not exceed 2MB!");
      }
      this.loading = false;
      return isValidMime;
    },

    handleLogoError(res) {
      res = JSON.parse(res.message);
      if (!res.success) {
        this.fileUploadError = res.message;
      }
      this.loading = false;
    },

    async upload(file) {
      let formData = new FormData();
      // this.currentImage = this.$refs.file.files.item(0);
      formData.append("files", file);
      formData.append("pathRepo", "DOCUMENT");

      let uploadInfo = await axios.post("/uploadToS3", formData);
      console.log(uploadInfo);

      this.$set(this.field, "upload_file", {
        type: "DOCUMENT",
        name: file.name,
        file: file,
        new: true,
        file_name: file.name,
        file_type: file.raw.type,
        file_size_in_kb: file.size / 1024,
      });
    },

    linkSubmit() {
      this.loading = true;
      this.field.video_thumbnail_url = this.video_thumbnail_url;
      this.loading = false;
    },
  },
};
</script>


<style>
.avatar-uploader {
  height: inherit !important;
  justify-content: center !important;
}

.attachment_img {
  height: inherit !important;
}
</style>